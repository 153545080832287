import { graphql, navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LayoutContext } from '../components/organisms/layoutProvider';
import { media } from '../utils/media-queries';
import SEO from '../components/organisms/seo';
import RichText from '../components/atoms/richtext';

const DisclaimerPage = ({ data }) => {
  const { language } = useContext(LayoutContext);
  const raw = language === 'en' ? '_rawEn' : '_rawDe';

  useEffect(() => {
    navigate(language === 'en' ? '/disclaimer' : '/de/disclaimer', {
      replace: true,
    });
  }, [language]);

  const { title, content } = data.disclaimer.nodes[0];

  return (
    <>
      <SEO siteTitle={title[language] || title.en} />
      <StyledDisclaimer className="spacing-inner">
        <p className="title">{title[language] || title.en}</p>
        <div className="content">
          <RichText blocks={content[raw] || content._rawEn} />
        </div>
      </StyledDisclaimer>
    </>
  );
};

const StyledDisclaimer = styled.section`
  min-height: 100vh;
  margin-top: var(--v-spacing-S);
  font-family: var(--font-1);

  .title {
    padding-bottom: var(--v-spacing-S);
  }

  .content {
    p {
      padding-bottom: var(--v-spacing-S);
    }
  }

  @media ${media.L} {
    .content {
      width: 33%;
    }
  }
`;

export const query = graphql`
  query Disclaimer {
    disclaimer: allSanityDisclaimer {
      nodes {
        title {
          de
          en
        }
        content {
          _rawDe
          _rawEn
        }
      }
    }
  }
`;

DisclaimerPage.propTypes = {
  data: PropTypes.object,
};

export default DisclaimerPage;
